video {
    object-fit: cover;
    width: 100.5%;
    height: 100%;
    position: fixed;
    z-index: -1;
    overflow: hidden;
}

.hero-container {
    background: url('https://react.brianmccracken.com/images/img-home.webp') center center/cover no-repeat;
    height: 100vh;
    width: 100.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
    justify-content: center;
    align-items: center;
}

.hero-container > h1 {
    font-size: 100px;
    Color: white;
    margin-top: -100px;
    text-shadow: 5px 5px 8px #000;
}

.hero-container > p {
    padding: .5%;
    color: white;
    font-size: 35px;
    margin: 30px;
}

a {
    text-decoration: none;
}

.first-button {
    background-color: transparent;
    color: #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    padding: 12px 26px;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin: 40px;
  }
  .second-button {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
    padding: 12px 26px;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin: 40px;
  }

  @media screen and (max-width: 960px) {
      .hero-container > h1 {
          font-size: 70px;
          margin-top: -150px;
      }
  }

  @media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: calc(250% + 0.75vw);
        margin-top: -10%;
        text-align: center;
    }
    .hero-container > p {
        font-size: 30px;
        margin: 10px;
        padding: 10px;
        justify-content: center;
        text-align: center;
    }
    .first-button, .second-button {
        display: block;
        width: 100%;
        margin: 10px;
        text-align: center;
    }
}