.footer-body {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000;
    width: 100.5%;
    overflow: hidden;
}