* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
  
  .inner__page__container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
}

.inner__content__container {
  max-width: 900px;
  overflow: hidden;
}

.inner__content__container > h1 {
  font-size: 60px;
  margin-bottom: 30px;
}

.inner__content__container > p {
  font-size: 28px;
  text-align: left;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.inner__content__container > img {
  margin: 10px;
}

.features__flexbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #fff;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}

.features__boxes {
	display: flex;
  flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: relative;
	color: #fff;
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	width: 300px;
	background: linear-gradient(0deg, #000, #262626);
  margin-left: .5%;
  margin-right: .5%;
  margin-top: 3%;
  margin-bottom: 3%;
    box-shadow:  0px 0px 10px #2a2a2a,
             -0px -0px 10px #2a2a2a;
    transition: transform .3s; /* Animation */
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 20px;
  padding-right: 20px;
}

.features__boxes > p {
  font-size: 26px;
}

.inner-page-button {
  background: linear-gradient(0deg, #000, #262626);
  padding: 25px 40px;
  margin: 40px;
  color: white;
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  .inner__content__container > h1 {
    font-size: 40px;
  }
  
  .inner__content__container > p {
    font-size: 22px;
  }
}