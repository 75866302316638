.whatis_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #000;
    width: 100.5%;
    height: 100vh;
    justify-content: center;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 0px;
    overflow: hidden;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 50%;
    background-color: #000;
}
.right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    color:#fff;
    padding-right: 15%;
}

.right > h3 {
    font-size: 30px;
    color: #fff;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 15px;
}
.right > p {
    font-size: 24px;
    color: #fff;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 45px;
}

.App-logo {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
  
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 10s linear;
    }
  }

@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


@media screen and (max-width: 1100px) {
    .whatis_container {
        height: 100vh;
    }
    .left, .right {
        width: 100%;
    }
    .left {
        height: 25vh;
        padding-top: 10%;
        padding-left: 30%;
        padding-right: 30%;

    }
    .right {
        height: 75vh;
        padding-top: 20px;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
    }
    .right > h3 {
        font-size: 26px;
        margin-bottom: 10px;
    }
    .right > p {
        font-size: 20px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 768px) {
    .whatis_container {
        height: 120vh;
    }
    .left, .right {
        width: 100%;
    }
    .left {
        height: 25vh;
        padding-left: 30%;
        padding-right: 30%;

    }
    .right {
        height: 75vh;
        padding-top: 20px;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
    }
    .right > h3 {
        font-size: 26px;
        margin-bottom: 10px;
    }
    .right > p {
        font-size: 20px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 420px) {
    .whatis_container {
        height: 120vh;
    }
    .left, .right {
        width: 100%;
    }
    .left {
        height: 25vh;
        padding-left: 30%;
        padding-right: 30%;

    }
    .right {
        height: 75vh;
        padding-top: 20px;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
    }
    .right > h3 {
        font-size: 26px;
        margin-bottom: 10px;
    }
    .right > p {
        font-size: 20px;
        margin-bottom: 15px;
    }
}





