.section__wrapper {
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 100.5%;
    height: 80vh;
    justify-content: center;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-left: 0px;
    overflow: hidden;
}

.section__intro {
    text-align: center;
    width: 100%;
}
.section__intro > h2 {
    font-size: 50px;
    color: #000;
    padding: 2%;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.portal-flexbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #fff;
    flex-wrap: wrap;
}

.portal__item {
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	color: #fff;
	text-align: center;
	font-size: 50px;
	font-weight: bold;
	width: 400px;
	height: 350px;
	background: linear-gradient(0deg, #000, #262626);
    margin: 1%;
    box-shadow:  0px 0px 10px #2a2a2a,
             -0px -0px 10px #2a2a2a;
    transition: transform .3s; /* Animation */
}

.portal__item:hover {
    transform: scale(1.05);
}

.portal__item > h1 {
    font-size: 40px;
}

.portal__item > p {
    font-size: 24px;
    margin: 10px;
}

.portal__item > img {
    margin: 10px;
}

@media screen and (max-width: 1300px) {
    .section__wrapper {
        height: 1000px;
        padding-bottom: 10px;
    }
    .portal__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        width: 85%;
        height: 200px;
        background: linear-gradient(0deg, #000, #262626);
        margin: 1%;
        box-shadow:  0px 0px 10px #2a2a2a,
                 -0px -0px 10px #2a2a2a;
        transition: transform .3s; /* Animation */
    }
    .portal__item > h1 {
        font-size: 30px;
    }
    
    .portal__item > p {
        font-size: 20px;
    }
    .section__intro > h2 {
        font-size: 40px;
        padding: 3%;
        }
}    

@media screen and (max-width: 768px) {
    .section__wrapper {
        height: 100vh;
        padding-bottom: 20vh;
    }
    .portal__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        width: 75%;
        height: 175px;
        background: linear-gradient(0deg, #000, #262626);
        margin: 5%;
        box-shadow:  0px 0px 10px #2a2a2a,
                 -0px -0px 10px #2a2a2a;
        transition: transform .3s; /* Animation */
    }
    .portal__item > h1 {
        font-size: 24px;
    }
    
    .portal__item > p {
        font-size: 20px;
    }
    .section__intro > h2 {
        font-size: 28px;
        padding: 5%;
        margin-top: 25%;
        }
} 

@media screen and (max-width: 420px) {
    .section__wrapper {
        height: 1000px;
        padding-bottom: 10px;
    }
    .portal__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #fff;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        width: 75%;
        height: 200px;
        background: linear-gradient(0deg, #000, #262626);
        margin: 5%;
        box-shadow:  0px 0px 10px #2a2a2a,
                 -0px -0px 10px #2a2a2a;
        transition: transform .3s; /* Animation */
    }
    .portal__item > h1 {
        font-size: 24px;
    }
    
    .portal__item > p {
        font-size: 16px;
    }
    .section__intro > h2 {
        font-size: 24px;
        margin-top: -30px;
    }
} 