.homectw_wrapper {
    display: flex;
    flex-direction: column;
    background-color: #61dbfb;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
    text-align: center;
    width: 100.5%;
    overflow: hidden;
}

.homecta_copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 950px;
    text-align: center;
}

.homecta_cards_holder {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.homecta_cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    text-align: center;
    background-color: #FAF9F6;
    max-width: 400px;
    height: 400px;
    margin: 15px;
    border-radius: 7.5px;
    box-shadow: 10px 10px 8px #888888;
    border: 1px solid #fff;
}

.homecta_cards_button {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #60a3bc;
    padding: 20px;
    border-radius: 50px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    text-align: center;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.homecta_cards_button:hover {
	text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
	-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	transition: all 0.4s ease 0s;
}

.homecta_cards > img {
    margin: 0px;
    max-width: 450px;
    border-radius: 7.5px;
}

.homecta_cards > h4 {
    font-size: 28px;
    color: #000;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-left: 10px;
    margin-right: 10px;
}

.homecta_cards > p {
    font-size: 18px;
    color: #6C6C6C;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-top: -15px;
    margin-left: 10px;
    margin-right: 10px;
}

.homectw_wrapper > h3 {
    font-size: 50px;
    color: #000;
    margin-bottom: 20px;
}

.homectw_wrapper > p {
    font-size: 36px;
    color: #FFF;
}

.homecta_copy > p {
    font-size: 36px;
    color: #292c30;
}

@media screen and (max-width: 768px) {
    .homectw_wrapper > h3 {
        font-size: 32px;
        color: #000;
        margin-bottom: 20px;
    }
    
    .homecta_copy > p {
        font-size: 18px;
        color: #FFF;
        padding: 10px;
    }

    .homecta_cards {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        text-align: center;
        background-color: #FAF9F6;
        max-width: 300px;
        height: 400px;
        border-radius: 7.5px;
        box-shadow: 10px 10px 8px #888888;
        border: 1px solid #fff;
        margin-left: 20px;
        margin-right: 20px;
    }

    .homecta_cards > h4 {
        font-size: 22px;
        color: #000;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .homecta_cards > p {
        font-size: 16px;
        color: #292c30;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        margin-top: -15px;
        margin-left: 10px;
        margin-right: 10px;
    }
}